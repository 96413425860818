import { useEffect } from "react";

/**
 * Redirects from
 * https://merge.dev/oauth/callback
 * to
 * https://app.merge.dev/oauth/callback
 *
 * NEVER DELETE THIS -- consult @henrybaer
 */
const OAuthCallback = () => {
  useEffect(() => {
    const url = `https://app.merge.dev${window.location.pathname + window.location.search}`;
    window.location.replace(url);
  }, []);
  return null;
};

export default OAuthCallback;
